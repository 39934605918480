<div id="about" class="story-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="story-image">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img1.jpg" alt="image" />
              <img src="assets/img/about/about-img2.jpg" alt="image" />
            </div>
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img3.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="story-content">
          <div class="section-title">
            <span class="text">About us</span>
            <h2>Welcome To Pizza Haven</h2>
          </div>
          <p>
            Aut recusandae minima ea laborum magnam sit dolores quibusdam eum
            voluptatem quisquam ut reprehenderit beatae non eligendi asperiores.
            Aut voluptatem quia in voluptatem pariatur sit sequi quisquam qui
            minus recusandae.
          </p>
          <p>
            Et earum quia sit tempora quod sed nulla voluptas ea natus
            doloremque ut tempore magni eos magnam quam. Id eius rerum veritatis
            molestiae eum veniam obcaecati cum enim sapiente est sunt aliquam et
            possimus iste. Qui voluptatem magni ea laborum omnis et officiis
            vitae ut unde dicta non tenetur delectus ut voluptates eligendi et
            nobis omnis.
          </p>
          <br />
          <p>Quality, you can trust</p>
          <div class="quote-info">
            <span> - The Pizza Haven Team</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
