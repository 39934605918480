import { Component } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { IGalleryItem } from '../../../interfaces/gallery-item.interface';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent {
  gallery: IGalleryItem[] = [
    {
      image: 'assets/img/gallery/gallery-img1.jpg',
      title: 'Kebab',
    },
    {
      image: 'assets/img/gallery/gallery-img2.jpg',
      title: 'Kebab',
    },
    {
      image: 'assets/img/gallery/gallery-img3.jpg',
      title: 'Chips',
    },
    {
      image: 'assets/img/gallery/gallery-img4.jpg',
      title: 'Food',
    },
    {
      image: 'assets/img/gallery/gallery-img5.jpg',
      title: 'Dumplings',
    },
    {
      image: 'assets/img/gallery/gallery-img6.jpg',
      title: 'Snacks',
    },
    {
      image: 'assets/img/gallery/gallery-img7.jpg',
      title: 'Rolls',
    },
    {
      image: 'assets/img/gallery/gallery-img8.jpg',
      title: 'Kebab',
    },
  ];

  constructor(public ngxSmartModalService: NgxSmartModalService) {}
}
