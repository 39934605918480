import { IConfig } from './config.interface';
/**
 * ! DO NOT MODIFY this file - If any change is required, please raise a PR with the change
 * ! in https://github.com/EdgeTechStudio/ReadyOrder_Demo
 *
 * Default Config for Storefront and Backend Apps.
 *
 * If looking to override this config, please update the `config.ts` file instead.
 */
export const baseConfig: IConfig = {
  awsConfig: {
    region: 'ap-southeast-2',
    account: '086745718740',
  },

  repository: 'ReadyOrder_Demo',

  appName: 'DemoPizzaHaven',

  storefront: {
    contact: {
      email: [
        {
          label: 'hello@readyorder.com.au',
          value: 'hello@readyorder.com.au',
        },
      ],
      phone: [{ label: '+61 400 000 000', value: '+61400000000' }],
      address: 'Some where on Earth',
      googleLink: 'https://www.google.com/maps/place',
    },
    openingHours: {
      week: { startTime: '8:30am', endTime: '5pm' },
      weekend: { startTime: '9am', endTime: '4:30pm' },
    },

    gloriaFoodUrl: 'https://pos.globalfoodsoft.com',
    restaurantId: '1RprxiWwRU8r6yPJ',
    gloriaFoodCuid: '05cd2cde-688a-49c5-ac6e-81cb45c4e7b3',
    gloriaFoodRuid: '28c0f6a9-fb00-4d5b-ba6d-0feeeeec5f8c',
  },

  domainName: 'storefront.readyorder.com.au',

  apiSubDomainPrefix: 'api',

  appSubDomainPrefix: 'demo',

  appDevSubDomainPrefix: 'demo-staging',

  mapRootDomain: true,

  certificateArn:
    'arn:aws:acm:ap-southeast-2:086745718740:certificate/6beedae1-4996-4e14-b3f7-f15f66fcd3a8',

  edgeCertificateArn: '',

  corsAllowedOrigins: ['*'],

  dnsHostingProvider: {
    isAWS: false,
  },

  awsMailHostingProvider: {
    sesIdentityArn:
      'arn:aws:ses:ap-southeast-2:086745718740:identity/readyorder.com.au',
    emailFromAddress: 'automation@readyorder.com.au',
    emailSubject: '[Test] New Website enquiry',
    emailToAddress: 'hello@readyorder.com.au',
  },
};
