import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IOfferItem } from '../../../interfaces/offer-item.interface';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent {
  offers: IOfferItem[] = [
    {
      title: "For 10'' - 12'' pizza - Wed-Friday only",
      description:
        'Pizza 12 inch + 1 Side Dish + 1,5L Coke Bottle Only From 209.000',
      offer_discount: '50% off',
      image: 'assets/img/offers/offer-img1.jpg',
    },
    {
      title: "For 12'' - 24'' pizza - Wed-Friday only",
      description:
        'Pizza 12 inch + 1 Side Dish + 1,5L Coke Bottle Only From 209.000',
      offer_discount: '50% off',
      image: 'assets/img/offers/offer-img2.jpg',
    },
    {
      title: "For 12'' - 24'' pizza - Wed-Friday only",
      description:
        'Pizza 12 inch + 1 Side Dish + 1,5L Coke Bottle Only From 209.000',
      offer_discount: '50% off',
      image: 'assets/img/offers/offer-img3.jpg',
    },
    {
      title: "For 12'' - 24'' pizza - Wed-Friday only",
      description:
        'Pizza 12 inch + 1 Side Dish + 1,5L Coke Bottle Only From 209.000',
      offer_discount: '50% off',
      image: 'assets/img/offers/offer-img4.jpg',
    },
  ];

  offerSlides: OwlOptions = {
    margin: 25,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
}
