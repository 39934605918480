<div id="gallery" class="gallery-area ptb-100 pb-0">
  <div class="container">
    <div class="section-title">
      <span class="text">Gallery</span>
      <h2>See Our Gallery</h2>
    </div>
  </div>
  <div class="row m-0">
    <div
      class="image-container col-lg-3 col-sm-6 col-md-6 p-0"
      *ngFor="let galleryItem of gallery; let i = index"
    >
      <div class="single-image">
        <img [src]="galleryItem.image" [alt]="galleryItem.title" />
        <button
          class="popup-btn"
          (click)="ngxSmartModalService.getModal('popup' + i).open()"
        >
          <i class="icofont-search-restaurant"></i>
        </button>
        <div class="image-content">
          <h3>{{ galleryItem.title }}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="image-popup">
  <ngx-smart-modal
    *ngFor="let galleryItem of gallery; let i = index"
    [identifier]="'popup' + i"
  >
    <img [src]="galleryItem.image" [alt]="galleryItem.title" />
  </ngx-smart-modal>
</div>
