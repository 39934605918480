<div class="instagram-area">
  <div class="instagram-title">
    <h3>
      <a href="https://www.instagram.com/" target="_blank"
        ><i class="icofont-instagram"></i> Follow Us On @PizzaHaven</a
      >
    </h3>
  </div>
  <div class="instagram-slides">
    <owl-carousel-o
      [options]="instagramSlides"
      class="instagram-item-container"
    >
      <ng-template carouselSlide *ngFor="let slide of slides">
        <div class="instagram-item" style="height: 350px">
          <a href="#" target="_blank">
            <img src="{{ slide.imageUrl }}" alt="{{ slide.altText }}" />
          </a>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>
