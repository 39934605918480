<div class="partner-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="text">Client</span>
      <h2>Our Partner</h2>
    </div>
    <div class="partner-slides">
      <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
          <div class="partner-item">
            <a href="#"
              ><img src="assets/img/partner/img1.png" alt="image"
            /></a>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="partner-item">
            <a href="#"
              ><img src="assets/img/partner/img2.png" alt="image"
            /></a>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="partner-item">
            <a href="#"
              ><img src="assets/img/partner/img3.png" alt="image"
            /></a>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="partner-item">
            <a href="#"
              ><img src="assets/img/partner/img4.png" alt="image"
            /></a>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="partner-item">
            <a href="#"
              ><img src="assets/img/partner/img5.png" alt="image"
            /></a>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="partner-item">
            <a href="#"
              ><img src="assets/img/partner/img6.png" alt="image"
            /></a>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
