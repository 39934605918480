import { environment } from '../../../../environments/environment';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-table-reservation-button',
  templateUrl: './table-reservation-button.component.html',
  styleUrls: ['./table-reservation-button.component.scss'],
})
export class TableReservationButtonComponent {
  public gloriaFoodCuid = environment.gloriaFoodCuid;
  public gloriaFoodRuid = environment.gloriaFoodRuid;

  @Input()
  isPrimaryColor!: boolean;
}
