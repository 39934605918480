import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss'],
})
export class InstagramComponent {
  readonly slides = [
    {
      imageUrl: 'assets/img/gallery/gallery-img1.jpg',
      altText: 'instagram-image',
    },
    {
      imageUrl: 'assets/img/gallery/gallery-img2.jpg',
      altText: 'instagram-image',
    },
    {
      imageUrl: 'assets/img/gallery/gallery-img3.jpg',
      altText: 'instagram-image',
    },
    {
      imageUrl: 'assets/img/gallery/gallery-img4.jpg',
      altText: 'instagram-image',
    },
    {
      imageUrl: 'assets/img/gallery/gallery-img5.jpg',
      altText: 'instagram-image',
    },
    {
      imageUrl: 'assets/img/gallery/gallery-img6.jpg',
      altText: 'instagram-image',
    },
  ];

  instagramSlides: OwlOptions = {
    margin: 20,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };
}
