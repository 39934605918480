<div id="chefs" class="chefs-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="text">Expert Chefs</span>
      <h2>Meet The Chefs</h2>
    </div>
    <div class="chefs-slides">
      <owl-carousel-o [options]="chefsSlides">
        <ng-template carouselSlide>
          <div class="single-chefs">
            <div class="chefs-image">
              <img src="assets/img/chefs/chef-img1.jpg" alt="chef-img" />
              <ul class="chefs-social">
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-facebook"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-twitter"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="chefs-content">
              <h3>John Smith</h3>
              <span>Master Chef</span>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-chefs">
            <div class="chefs-image">
              <img src="assets/img/chefs/chef-img2.jpg" alt="chef-img" />
              <ul class="chefs-social">
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-facebook"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-twitter"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="chefs-content">
              <h3>Steven King</h3>
              <span>Master Chef</span>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-chefs">
            <div class="chefs-image">
              <img src="assets/img/chefs/chef-img3.jpg" alt="chef-img" />
              <ul class="chefs-social">
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-facebook"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-twitter"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="chefs-content">
              <h3>Dora Caelan</h3>
              <span>Chef</span>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-chefs">
            <div class="chefs-image">
              <img src="assets/img/chefs/chef-img4.jpg" alt="chef-img" />
              <ul class="chefs-social">
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-facebook"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-twitter"></i
                  ></a>
                </li>
                <li>
                  <a href="#" target="_blank"
                    ><i class="icofont-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="chefs-content">
              <h3>James Anderson</h3>
              <span>Chef</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
