import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  readonly testimonials = [
    {
      message:
        'Lorem ipsum dolor sit amet. Vel incidunt autem ut tempore eaque non fugit praesentium. At vitae porro quo illo omnis ut fuga illum id molestias atque aut ullam voluptatem sit quisquam laboriosam 33 esse expedita..',
      userName: 'Someone',
    },
    {
      message:
        'Lorem ipsum dolor sit amet. Vel incidunt autem ut tempore eaque non fugit praesentium. At vitae porro quo illo omnis ut fuga illum id molestias atque aut ullam voluptatem sit quisquam laboriosam 33 esse expedita.',
      userName: 'Someone',
    },
    {
      message:
        'Lorem ipsum dolor sit amet. Vel incidunt autem ut tempore eaque non fugit praesentium. At vitae porro quo illo omnis ut fuga illum id molestias atque aut ullam voluptatem sit quisquam laboriosam 33 esse expedita.',
      userName: 'Someone',
    },
    {
      message:
        'Lorem ipsum dolor sit amet. Vel incidunt autem ut tempore eaque non fugit praesentium. At vitae porro quo illo omnis ut fuga illum id molestias atque aut ullam voluptatem sit quisquam laboriosam 33 esse expedita.',
      userName: 'Someone',
    },
    {
      message:
        'Lorem ipsum dolor sit amet. Vel incidunt autem ut tempore eaque non fugit praesentium. At vitae porro quo illo omnis ut fuga illum id molestias atque aut ullam voluptatem sit quisquam laboriosam 33 esse expedita.',
      userName: 'Someone',
    },
  ];

  feedbackSlides: OwlOptions = {
    items: 1,
    margin: 0,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
  };
}
