import { baseConfig } from './base-config';
import { IConfig } from './config.interface';

/**
 * Client specific overrides
 */
export const config: IConfig = {
  ...baseConfig,
  //! All client specific override needs to be applied after here.

  /**
   * Enable this to use default shared api hosted at api.storefront.readyorder.com.au
   */
  // apiSubDomainPrefix: null,
};
