<div class="stunning-things ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="text">For Your Comfort</span>
      <h2>Stunning Things</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-restaurant"></i>
          </div>
          <h3>High Quality Foods</h3>
          <p>
            Every dish at Pizza Haven is made with the finest ingredients
            sourced from local markets. Our quality is guaranteed by our own
            quality control team
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-sale-discount"></i>
          </div>
          <h3>Accessible and affordable</h3>
          <p>
            We offer numerous payment options so you can get what you want, when
            you need it. We also offer bulk discounts on large orders, ensuring
            that our prices remain competitive.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-culinary"></i>
          </div>
          <h3>Salutary Meals</h3>
          <p>
            It's not just about pizzas, we also offer healthy and tasty salads
            that will keep your appetite happy and your stomach satisfied.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-chef"></i>
          </div>
          <h3>Veteran Staff</h3>
          <p>
            Pizza Haven has been serving the community for over 12 years, with
            veteran staff who are passionate about cooking and serving delicious
            food to all customers
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-chicken-fry"></i>
          </div>
          <h3>Pristine Ingredients</h3>
          <p>
            All of our ingredients are fresh and carefully sourced, so you know
            what you're eating is good quality.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-box">
          <div class="icon">
            <i class="icofont-cart"></i>
          </div>
          <h3>Effortless ordering</h3>
          <p>
            Skip the queue by ordering online, it's quick, easy and at your
            fingertips
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
